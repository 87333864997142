import { createSlice } from "@reduxjs/toolkit";




const candidateSlice = createSlice({
    name:"candidate",
    initialState:{
        isCandidate : false,
    },
    reducers:{
        setIsCandidate:(state,action)=>{
            state.isCandidate = action.payload;
        }
    }
})


export const {setIsCandidate} = candidateSlice.actions;

export default candidateSlice.reducer;