import { useState } from "react";
import educationProfile from "../../images/ed1.png";
import AddEducation from "./AddEducation";
import RotateBtn from "components/Button/RotateBtn";
import { Alert, Snackbar } from "@mui/material";
import { MdDelete } from "react-icons/md";
import { deleteEducationItem } from "components/Fetchers";
import editImg from "../../images/edit.png";
import ToastMsg from "./ToastMsg";
import { useSelector } from "react-redux";

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "short" };
  return new Date(dateString).toLocaleString("en-US", options);
};

const EducationInfo = ({ candidateData }) => {
  const { education = [] } = candidateData;
  const [openInput, setOpenInput] = useState(false);
  const [educationList, setEducationList] = useState(education);
  const [isToast, setIsToast] = useState(false);
  const [editEducation, setEditEducation] = useState(null);
  const isCandidate = useSelector((store)=>store?.candidate?.isCandidate);
  const isBtnDisabled = useSelector((store)=>store?.btns?.isBtnDisabled);
  

  const handleEducation = (educationObj) => {
    if (editEducation) {
      setEducationList((prevList) =>
        prevList.map((edu) =>
          edu._id === editEducation._id ? educationObj : edu
        )
      );
    } else {
      setEducationList((prevList) => [...prevList, educationObj]);
    }
    setIsToast(true);
    setOpenInput(false);
  };

  const handleDelete = async (id) => {
    try {
      await deleteEducationItem(candidateData._id, id);
      const newArray = educationList.filter((item) => item._id !== id);
      setEducationList(newArray);
      setOpenInput(false);
    } catch (error) {
      console.error("Failed to delete education item:", error);
    }
  };

  const handleEdit = (id) => {
    const educationItem = educationList.find((item) => item._id === id);
    setEditEducation(educationItem);
    setOpenInput(true);
  };

  const renderEducationList = () => {
    if (educationList.length === 0) {
      return <p className="text-gray-500">No Studies available.</p>;
    }

    return educationList.map((edu) => {
      const formattedStartDate = formatDate(edu.startDate);
      const formattedEndDate = formatDate(edu.endDate);

      return (
        <div className="px-4 py-4 border-b-4 border-blue-500" key={edu._id}>
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex items-center space-x-4">
              <img
                className="w-12 h-12"
                src={educationProfile}
                alt="Education Logo"
              />
              <div>
                <h4 className="font-semibold text-base sm:text-lg m-0">
                  {edu.collegeName}
                </h4>
                <p className="text-sm text-gray-500 m-0">{edu.courseName}</p>
                <p className="text-sm text-gray-400 m-0">{edu.cgpa}</p>
                <span className="sm:hidden text-sm sm:text-base text-gray-500 mt-2 sm:mt-0">
                  {formattedStartDate} - {formattedEndDate}
                </span>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <span className="hidden md:block text-sm sm:text-base text-gray-500 mt-2 sm:mt-0">
                {formattedStartDate} - {formattedEndDate}
              </span>
             {!isBtnDisabled &&  isCandidate && <div className="ml-2 cursor-pointer">
                <img
                  src={editImg}
                  className="w-12 h-12"
                  onClick={() => handleEdit(edu._id)}
                  alt="Edit"
                />
              </div>}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="bg-white p-6 rounded-md shadow-md md:mb-10">
    { !isBtnDisabled &&  <RotateBtn
        head="Add Education"
        setAddInput={setOpenInput}
        addInput={openInput}
      />}
      {openInput && (
        <AddEducation
          candidateData={candidateData}
          handleEducation={handleEducation}
          editEducationData={editEducation ? [editEducation] : []}
          handleDelete={handleDelete}
        />
      )}
      {renderEducationList()}
      <ToastMsg
        isToast={isToast}
        setIsToast={setIsToast}
        data="Education added successfully!"
        severity="success"
      />
    </div>
  );
};

export default EducationInfo;
