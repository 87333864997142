import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";
import axios from "axios";
import Filters from "components/Jobboard/Filters";
import FreeSoloFilter from "components/Jobboard/FreeSoloFilter";
import Filter from "components/Jobboard/Filter";
import Jobslist from "components/Jobboard/Jobslist";
import "components/Jobboard/style.css";
import Pagination from "@mui/material/Pagination";
import Navbar from "components/Navbar";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import WorkOutlinedIcon from "@mui/icons-material/WorkOutlined";
import Box from "@mui/material/Box";
import Multifilter from "components/Jobboard/Multifilter";
import { arrayMove } from "@dnd-kit/sortable";
// import { getUnappliedJobs, publicJobPosts,fetchCandidateData ,getRecommendedJobs } from 'components/Fetchers/candidate';
import {
  getUnappliedJobs,
  publicJobPosts,
  fetchCandidateData,
  getRecommendedJobs,
} from "components/Fetchers";
import { useAuth } from "utils/auth";
import { useSearchParams } from "react-router-dom";
import NoJobShimmerUI from "components/Shimmer/JobShimmer";
import NoJobsList from "components/NoDataPlaceHolder/NoJobsList";
import Pagination2 from "../components/Pagination/Pagination2";
import { PerPage } from "utils/constants";
import NewNavbar from "components/Layout/NewNavbar";
import { Helmet } from "react-helmet";

// The default value of location.state is null if you don't pass any data.
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Jobboard() {
  const [loading, setLoading] = useState(true);
  const [matchPercentage, setMatchPercentage] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalJobs, setTotalJobs] = useState(0);
  const navigate = useNavigate();
  const auth = useAuth();
  const [showJobs, setShowJobs] = useState([]);
  const jobtype = ["Internship", "Fulltime", "Parttime", "Freelancer"];
  const exp = ["1", "2", "3", "4", "5+"];
  const careerlevel = ["Starter", "Expert"];
  const skills = [
    "HTML",
    "CSS",
    "Java",
    "C++",
    "Python",
    "Reactjs",
    "Redux",
    "UI/UX",
  ];
  const [jobtypeFilter, setJobtypeFilter] = useState({
    jobTitle: [],
    jobLocation: [],
    jobCategory: [],
    jobSkills: [],
    jobType: [],
  });
  const [candidateData, setCandidateData] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [jobTypeSearch, setJobTypeSearch] = useState({
    jobTitle: "",
    jobLocation: "",
    jobCategory: "",
    jobType: "",
  });

  const getMatchPercentage = async () => {
    try {
      const jobData = filteredJobs.map((job) => ({
        must_have_skills: job.mustHaveSkills.map(skill => skill.toLowerCase()),
        good_to_have_skills: job.goodToHaveSkills.map(skill => skill.toLowerCase()),
      }));


      const candidateSkills = Array.isArray(candidateData?.skills)
        ? candidateData.skills.map(skill => skill.toLowerCase())
        : candidateData?.skills?.toLowerCase();

      const response = await fetch(
        `${process.env.REACT_APP_API__URL}/api/flask/fetch-matching-skills`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            job_descriptions: jobData,
            candidates: [
              {
                github_username: candidateData?.devProfile?.github,
                leetcode_username: candidateData?.devProfile?.leetcode,
                resume_skills: candidateSkills,
              },
            ],
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error: ${errorData.message || "Unknown error"}`);
      }

      const dataFromApi = await response.json();
      const matchMap = {};

      dataFromApi?.result?.forEach((match, index) => {
        const jobId = filteredJobs[index]._id;
        if (match.ranked_candidates && match.ranked_candidates[0]) {
          matchMap[jobId] = match.ranked_candidates[0]?.match_percentage;
        }
      });

      setMatchPercentage(matchMap);
    } catch (error) {
      console.error("Fetch error:", error.message);
    }
  };


  useEffect(() => {
    if (filteredJobs.length === 0) return;
    getMatchPercentage();
  }, [candidateData]);

  const getJobCategoryFromList = (list) => {
    const jobCategory = list.map((job) => job.category);
    const jobCategorySet = new Set(jobCategory);
    const jobCategoryArray = Array.from(jobCategorySet);
    return jobCategoryArray;
  };

  const getJobTitleFromList = (list) => {
    const jobTitle = list.map((job) => job.title);
    const jobTitleSet = new Set(jobTitle);
    const jobTitleArray = Array.from(jobTitleSet);
    return jobTitleArray;
  };

  const getJobLocationFromList = (list) => {
    const jobLocation = list.map((job) => job.location);
    const jobLocationSet = new Set(jobLocation);
    const jobLocationArray = Array.from(jobLocationSet);
    return jobLocationArray;
  };

  const getJobTypeFromList = (list) => {
    const jobType = list.map((job) => job.type);
    const jobTypeSet = new Set(jobType);
    const jobTypeArray = Array.from(jobTypeSet);
    return jobTypeArray;
  };
  const getSkillsFromList = (list) => {
    //get items from array of objects

    const mustHavejobSkills = list.map((job) => job.mustHaveSkills);
    const goodToHavejobSkills = list.map((job) => job.goodToHaveSkills);
    // get array items in array
    const mustHavejobSkillsArray = mustHavejobSkills.flat().flat();
    const goodToHavejobSkillsArray = goodToHavejobSkills.flat().flat();

    // console.log(mustHavejobSkills)
    const jobSkills = mustHavejobSkillsArray.concat(goodToHavejobSkillsArray);
    const jobSkillsSet = new Set(jobSkills);
    const jobSkillsArray = Array.from(jobSkillsSet);
    return jobSkillsArray;
  };

  const handleParamSearch = (job) => {
    const jobSkillsArray = jobSkillsGetter(job);
    const jobTitleArray = jobTitleGetter(jobSkillsArray);
    const jobLocationArray = jobLocationGetter(jobTitleArray);
    const jobCategoryArray = jobCategoryGetter(jobLocationArray);
    const jobTypeArray = jobTypeGetter(jobCategoryArray);
    setFilteredJobs(jobTypeArray);
  };

  const handleSearch = () => {
    const jobSkillsArray = jobSkillsGetter(showJobs);
    const jobTitleArray = jobTitleGetter(jobSkillsArray);
    const jobLocationArray = jobLocationGetter(jobTitleArray);
    const jobCategoryArray = jobCategoryGetter(jobLocationArray);
    const jobTypeArray = jobTypeGetter(jobCategoryArray);
    setFilteredJobs(jobTypeArray);
  };

  const paramSearcher = (searchParams, job) => {
    if (searchParams.get("title")) {
      jobTypeSearch.jobTitle = [searchParams.get("title")];
    }
    if (searchParams.get("location")) {
      jobTypeSearch.jobLocation = [searchParams.get("location")];
    }
    if (searchParams.get("type")) {
      jobTypeSearch.jobType = [searchParams.get("type")];
    }
    if (searchParams.get("category")) {
      jobTypeSearch.jobCategory = [searchParams.get("category")];
    }
    if (
      searchParams.get("title") ||
      searchParams.get("location") ||
      searchParams.get("type") ||
      searchParams.get("category")
    ) {
      handleParamSearch(job);
    }
  };

  const jobSkillsGetter = (job) => {
    let arrayOfJobs = [];
    job.forEach((job) => {
      if (
        jobTypeSearch.jobSkills == "" ||
        jobTypeSearch.jobSkills == undefined
      ) {
        arrayOfJobs.push(job);
      } else {
        jobTypeSearch.jobSkills.map((skill) => {
          if (
            job.mustHaveSkills.includes(skill) ||
            job.goodToHaveSkills.includes(skill)
          ) {
            arrayOfJobs.push(job);
          }
        });
      }
    });
    return arrayOfJobs;
  };
  const jobTitleGetter = (job) => {
    let arrayOfJobs = [];
    job.forEach((job) => {
      if (jobTypeSearch.jobTitle == "" || jobTypeSearch.jobTitle == undefined) {
        arrayOfJobs.push(job);
      } else {
        jobTypeSearch.jobTitle.map((title) => {
          if (job.title.toLowerCase().includes(title.toLowerCase())) {
            arrayOfJobs.push(job);
          }
        });
      }
    });
    return arrayOfJobs;
  };
  const jobLocationGetter = (job) => {
    let arrayOfJobs = [];
    job.forEach((job) => {
      if (
        jobTypeSearch.jobLocation == "" ||
        jobTypeSearch.jobLocation == undefined
      ) {
        arrayOfJobs.push(job);
      } else {
        jobTypeSearch.jobLocation.map((location) => {
          if (job.location.toLowerCase().includes(location.toLowerCase())) {
            arrayOfJobs.push(job);
          }
        });
      }
    });
    return arrayOfJobs;
  };
  const jobTypeGetter = (job) => {
    let arrayOfJobs = [];
    job.forEach((job) => {
      if (jobTypeSearch.jobType == "" || jobTypeSearch.jobType == undefined) {
        arrayOfJobs.push(job);
      } else {
        jobTypeSearch.jobType.map((type) => {
          if (job.type.toLowerCase().includes(type.toLowerCase())) {
            arrayOfJobs.push(job);
          }
        });
      }
    });
    return arrayOfJobs;
  };
  const jobCategoryGetter = (job) => {
    let arrayOfJobs = [];
    job.forEach((job) => {
      if (
        jobTypeSearch.jobCategory == "" ||
        jobTypeSearch.jobCategory == undefined
      ) {
        arrayOfJobs.push(job);
      } else {
        jobTypeSearch.jobCategory.map((category) => {
          if (job.category.toLowerCase().includes(category.toLowerCase())) {
            arrayOfJobs.push(job);
          }
        });
      }
    });
    return arrayOfJobs;
  };

  const jobSetter = (job) => {
    setShowJobs(job);
    setJobtypeFilter({
      jobTitle: getJobTitleFromList(job),
      jobLocation: getJobLocationFromList(job),
      jobCategory: getJobCategoryFromList(job),
      jobSkills: getSkillsFromList(job),
      jobType: getJobTypeFromList(job),
    });
    setFilteredJobs(job);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage, setJobsPerPage] = useState(10);

  const lastIndex = currentPage * jobsPerPage;
  const firstIndedx = lastIndex - jobsPerPage;

  const [filterButton, setFilterButton] = useState(true);
  const [selectedJobsPerPage, setSelectedJobsPerPage] = useState(
    PerPage[0].value
  );
  const [isDropDown, setIsDropDown] = useState(false);

  const handleDropDown = (pages) => {
    setSelectedJobsPerPage(pages);
    setJobsPerPage(pages);
    setIsDropDown(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchJobs();
  }, [selectedJobsPerPage, currentPage]);

  const fetchJobs = async () => {
    try {
      let jobs;
      if (
        searchParams.get("title") ||
        searchParams.get("location") ||
        searchParams.get("type") ||
        searchParams.get("category")
      ) {
        const data = await publicJobPosts();
        setTotalJobs(data?.totalRecords ?? 0);
        jobs = data?.result ?? [];
        jobSetter(jobs.reverse());
        paramSearcher(searchParams, jobs);
      } else {
        if (auth.userType === "candidate") {
          const data = await getUnappliedJobs(currentPage, selectedJobsPerPage);
          setTotalJobs(data?.totalRecords ?? 0);
          jobs = data?.result ?? [];
          jobSetter(jobs.reverse());

          // getRecommendedJobs().then((res) => {
          //     console.log('RECOMMENDED JOBS')
          //     jobSetter(res)
          // })

          // console.log('candidate')
          fetchCandidateData(auth.userId).then((res) => {
            // console.log('CANDIDATE DATA')
            // console.log(res.data.result.skills)

            setCandidateData(res.data.result);
            //getMatchPercentage();

          });

        } else {
          const data = await publicJobPosts(currentPage, selectedJobsPerPage);
          setTotalJobs(data?.totalRecords ?? 0);
          jobs = data?.result ?? [];
          jobSetter(jobs.reverse());
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };



  //     axios.get(`${process.env.REACT_APP_API__URL}/api/unappliedJobs`)
  //         .then((res) => {
  //             console.log(res.data.result)
  //             if (res.data.result) {
  //                 setShowJobs(res.data.result)
  //             }
  //             setShowJobs(res.data.result)
  //             setJobtypeFilter({
  //                 jobTitle:getJobTitleFromList(res.data.result),
  //                 jobLocation:getJobLocationFromList(res.data.result),
  //                 jobCategory:getJobCategoryFromList(res.data.result),
  //                 jobSkills:getSkillsFromList(res.data.result),
  //                 jobType:getJobTypeFromList(res.data.result)
  //             })

  //             setFilteredJobs(res.data.result)
  //         })
  //         .catch((err) => {

  //             console.log(err)
  //             navigate('/login')
  //         })
  // },[])

  const handleApplyAfterEffect = (id, bool) => {
    if (bool) {
      const newJobs = showJobs.filter((job) => job._id !== id);
      setFilteredJobs(newJobs);
      onSuccess();
    } else {
      if (auth.userType === "candidate") {
        onError();
      } else {
        onErrorPublic();
      }
    }
  };

  // const newJobs = showJobs.filter((job) => job._id !== id)
  // setShowJobs(newJobs)
  // onSuccess()

  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorOpenPublic, setErrorOpenPublic] = useState(false);

  const onSuccess = () => {
    setSuccessOpen(true);
  };
  const onError = () => {
    setErrorOpen(true);
  };
  const onErrorPublic = () => {
    setErrorOpenPublic(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
    setErrorOpenPublic(false);
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <Helmet>
        <title>Workcrew.ai:Search Jobs and explore the trendy,featured jobs and opportunities!</title>
        <meta name="description" content="Explore the trendy, featured jobs and opportunities of the tech world." />
        <meta name="Keywords" content="Software Engineer, Data Scientist, Data Analyst, DevOps Engineer, Junior Software Engineer, Senior Software Engineer, Full Stack Developer, Backend Developer, Frontend Developer, UI/UX Designer, Machine Learning Engineer, Artificial Intelligence Engineer, Cloud Architect, Cloud Engineer, System Architect, IT Project Manager, Product Manager, Network Engineer, QA Engineer, Test Engineer, Security Engineer, IT Support Specialist, Site Reliability Engineer, Blockchain Developer, Mobile App Developer, iOS Developer, Android Developer, Web Developer, Database Administrator, Data Engineer, Automation Engineer, Business Analyst, Solutions Architect, Technical Lead, Scrum Master, IT Consultant, Cloud Solutions Architect, Systems Administrator, Technical Project Manager, Embedded Systems Engineer, C++ Developer, Java Developer, Python Developer, Ruby Developer, PHP Developer, Node.js Developer, React Developer, Angular Developer, Django Developer, .NET Developer, Go Developer, Kubernetes Engineer, AWS Solutions Architect, Azure Engineer, Google Cloud Engineer, SAP Consultant, SAP Developer, Technical Writer, Big Data Engineer, Game Developer, Network Architect, Virtualization Engineer, IT Security Analyst, Cybersecurity Specialist, Penetration Tester, Incident Responder, Digital Marketing Specialist, SEO Specialist, Salesforce Developer, CRM Developer, IT Operations Manager, Cloud Infrastructure Engineer, IT Director, Web Designer, IT Analyst, Cloud Consultant, Hardware Engineer, Software Tester, Systems Engineer" />
      </Helmet>
      <NewNavbar />

      {/* <Filters /> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "column" },
          justifyContent: "space-between",
          maxWidth: { xs: "100%", md: "xl" },
          padding: { xs: "10px", md: "20px" },
        }}
      >
        {filterButton ? (
          <Box
            className="UpperFilters animate-slide-down items-center"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              margin: { xs: "0px", md: "0px 10px" },
              gap: { xs: "10px", md: "20px" },
              width: "100%",
            }}
          >
            <Multifilter
              optionList={jobtypeFilter.jobTitle}
              value={jobTypeSearch.jobTitle ? jobTypeSearch.jobTitle : []}
              handleChange={(value) => {
                setJobTypeSearch({
                  ...jobTypeSearch,
                  jobTitle: value,
                });
              }}
              wid="85%"
              label="Title"
              sticker={<SearchIcon />}
              size="medium"
              color="#F5F5F5"
              heading="Job Title"
            />

            <Multifilter
              optionList={jobtypeFilter.jobLocation}
              value={jobTypeSearch.jobLocation ? jobTypeSearch.jobLocation : []}
              handleChange={(value) => {
                setJobTypeSearch({
                  ...jobTypeSearch,
                  jobLocation: value,
                });
              }}
              wid="85%"
              label="City"
              sticker={<LocationOnOutlinedIcon />}
              size="medium"
              color="#F5F5F5"
              heading="Location"
            />

            <Multifilter
              optionList={jobtypeFilter.jobCategory}
              value={jobTypeSearch.jobCategory ? jobTypeSearch.jobCategory : []}
              handleChange={(value) => {
                setJobTypeSearch({
                  ...jobTypeSearch,
                  jobCategory: value,
                });
              }}
              wid="85%"
              label="Categories"
              sticker={<WorkOutlinedIcon />}
              size="medium"
              color="#F5F5F5"
              heading="Categories"
            />
            <button
              className="filters__search items-start"
              onClick={handleSearch}
            >
              Search
            </button>
            <button
              className="filters__search md:hidden"
              onClick={() => {
                setFilterButton(!filterButton);
              }}
            >
              Close
            </button>
          </Box>
        ) : (
          <button
            className="filters__search md:hidden"
            onClick={() => {
              setFilterButton(!filterButton);
            }}
          >
            Filter
          </button>
        )}
        {/* <TopFilters /> */}

        <div className="flex m-0 md:mx-[80px] my-[70px]">
          {/* Jobs List Section */}
          <div className="w-full">
            <div className="flex-wrap md:flex">
              <p className="joblist__h mb-0 md:mb-3">
                Showing {filteredJobs.length} out of {totalJobs} results
              </p>
              {/* <p className='joblist__h'>Showing 1 – 10 of {filteredJobs.length} results</p> */}

              <div
                className="md:flex ml-auto"
                style={{ display: "flex", marginLeft: "auto" }}
              >
                <div className="relative mb-2 mt-2 md:mb-0 md:mt-0">
                  <button
                    onClick={() => setIsDropDown(!isDropDown)}
                    className="flex justify-between items-center w-40 px-4 py-2 bg-[#F5F5F5] text-sm font-medium text-gray-700 border border-gray-300 rounded-md  shadow-sm hover:bg-gray-50"
                  >
                    {`${selectedJobsPerPage} Per Page`}
                    <IoMdArrowDropdown />
                  </button>
                  {isDropDown && (
                    <div className="absolute right-0 z-10 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                      <div className="py-1">
                        {PerPage.map((page) => {
                          return (
                            <p
                              key={page.id}
                              onClick={() => handleDropDown(page.value)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                            >
                              {page.title}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {loading ? (
              <NoJobShimmerUI />
            ) : filteredJobs.length > 0 ? (
              filteredJobs
                ?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                ?.map((job) => {
                  const jobMatchPercentage = matchPercentage?.[job._id];
                  return (
                    <Jobslist
                      key={job._id}
                      job={job}
                      handleApplyAfterEffect={(e, bool) =>
                        handleApplyAfterEffect(e, bool)
                      }
                      candidateSkills={candidateData.skills}
                      candidateData={candidateData}
                      skillMatches={jobMatchPercentage}
                    />
                  );
                })
            ) : (
              <NoJobsList data="Sorry, No Jobs Available!" />
            )}

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Pagination2
                jobsPerPage={jobsPerPage}
                totalJobs={totalJobs}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>

          {/* Filter Section */}
          <div
            className="hidden lg:block"
            style={{ width: "60%", marginLeft: "43px" }}
          >
            <p className="jobboard__filterheading">Filter</p>
            <Multifilter
              optionList={jobtypeFilter.jobType}
              value={jobTypeSearch.jobType ? jobTypeSearch.jobType : []}
              handleChange={(value) => {
                setJobTypeSearch({
                  ...jobTypeSearch,
                  jobType: value,
                });
              }}
              wid="85%"
              label="Type"
              size="medium"
              color="#F5F5F5"
              heading="Job Type"
            />
            {/* <Filter optionList={jobtypeFilter.jobType}
                        handleChange={(value) => {
                            setJobTypeSearch({
                                ...jobTypeSearch,
                                jobType: value
                            })
                        }}
                        wid="85%" label="Job Type" size="medium" color="#F5F5F5" heading="Job Type" /> */}
            {/* <Filter optionList={exp} wid="85%" label="Years" size="medium" color="#F5F5F5" heading="Experience" />
                    <Filter optionList={careerlevel} wid="85%" label="Starter" size="medium" color="#F5F5F5" heading="Career Level" /> */}

            {/* <div>
                        <p className='jobboard__search'>Most Searched</p>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <p className='search__fields'>Java</p>
                            <p className='search__fields'>Data Scientist</p>
                            <p className='search__fields'>Developer</p>
                            <p className='search__fields'>UI/UX Designer</p>
                            <p className='search__fields'>Frontend Developer</p>
                        </div>
                    </div> */}

            <Multifilter
              optionList={jobtypeFilter.jobSkills}
              value={jobTypeSearch.jobSkills ? jobTypeSearch.jobSkills : []}
              handleChange={(value) => {
                setJobTypeSearch({
                  ...jobTypeSearch,
                  jobSkills: value,
                });
              }}
              wid="85%"
              label="Skills"
              size="medium"
              color="#F5F5F5"
              heading="Skills"
            />
            <div>
              {/* {jobTypeSearch?.jobSkills?.length > 0 && (<>
                            <p className='jobboard__search'>Selected</p>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {jobTypeSearch.jobSkills.map((skill) => {
                                    return (<p className='search__fields'>{skill}</p>)
                                })}

                            </div>
                        </>)} */}
            </div>
          </div>
        </div>
      </Box>
      <Snackbar
        open={errorOpenPublic}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} sx={{ width: "100%" }} severity="error">
          Please Sign Up
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} sx={{ width: "100%" }} severity="error">
          Cant Apply to the job, Please Try Again
        </Alert>
      </Snackbar>
      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} sx={{ width: "100%" }} severity="success">
          Job Applied!
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Jobboard;
