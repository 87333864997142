import { createSlice } from "@reduxjs/toolkit";

const btnsSlice = createSlice({
    name:"btns",
    initialState:{
        isBtnDisabled : false,
    },
    reducers:{
        setIsBtnDisabled:(state,action)=>{
            state.isBtnDisabled = action.payload;
        }
    }
})


export const {setIsBtnDisabled} = btnsSlice.actions;

export default btnsSlice.reducer;