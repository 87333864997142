import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import axios from "axios";
// import { patchCandidateData} from "components/Fetchers/candidate";
import { patchCandidateData } from "components/Fetchers";
import ToastMsg from "../ToastMsg";
import { useSelector } from "react-redux";
export const EditContactField = ({
  value,
  subValue,
  setInputValue,
  candidateId,
  candidate,
  editFields,
}) => {
  const [edit, setEdit] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const isCandidate = useSelector((store)=>store?.candidate?.isCandidate);
  const isBtnDisabled = useSelector((store)=>store?.btns?.isBtnDisabled);
  // if (value[subValue] === "Enter Address" || value[subValue] === "Enter Phone Number" || value[subValue] === "Enter Email" || value[subValue] === "Enter Location") {
  //   value[subValue] = "";
  // }
  const cominationCalcultor = (value) => {
    let firstComination = value.split(",");
    let secondComination = value.split(" ");
    let thirdComination = value.split(" , ");
    let fourthComination = value.split(", ");
    let fifthComination = value.split(" ,");

    // find out the longest combination
    let longestCombination = firstComination;
    if (secondComination.length > longestCombination.length) {
      longestCombination = secondComination;
    }
    if (thirdComination.length > longestCombination.length) {
      longestCombination = thirdComination;
    }
    if (fourthComination.length > longestCombination.length) {
      longestCombination = fourthComination;
    }
    if (fifthComination.length > longestCombination.length) {
      longestCombination = fifthComination;
    }
    return longestCombination;
  };

  const patchField = async (key, value) => {
    if (key === "languages") {
      value = cominationCalcultor(value);
    }
    if (key === "name" || key === "email") {
      var patchData = { [key]: value };
    } else {
      // var patchData = { contactInfo: { [key]: value } }
      // if (key === "languages") {
      //   var patchData = { address: editFields.address,
      //     phone: editFields.phone,
      //     address: editFields.address,
      //     [key]: value }
      // }
      // else if
      var contactInfo = contactInfoGenerator(key, value);
      var patchData = { contactInfo: contactInfo };
      console.log(patchData);
    }
    console.log(patchData);

    patchCandidateData(candidateId, patchData).then(
      (res) => {
        console.log(res.data);
        // console.log(key);
        // console.log(value);
        setIsToast(true);
      },
      (err) => {
        console.log(err);
      }
    );

    // axios.patch(
    //   `${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`, patchData
    // ).then((res) => {
    //   console.log(res.data);
    //   console.log(key);
    //   console.log(value);
    // }, err => {
    //   console.log(err);
    // });
  };

  const resumeDataPatcher = async () => {
    console.log("runing");
    var patchContactInfo = {
      contactInfo: {
        address: editFields.address,
        phone: editFields.phone,
        exp: editFields.exp,
        languages: editFields.languages,
      },
    };

    const patchName = { name: editFields.name };
    const patchEmail = { email: editFields.email };
    console.log(patchContactInfo);
    console.log(patchName);
    console.log(patchEmail);
    axios
      .all([
        axios.patch(
          `${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`,
          patchContactInfo
        ),
        axios.patch(
          `${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`,
          patchName
        ),
        axios.patch(
          `${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`,
          patchEmail
        ),
      ])
      .then(
        axios.spread((...responses) => {
          const responseOne = responses[0];
          const responseTwo = responses[1];
          const responseThree = responses[2];
          console.log("YESSSS");
          console.log(responseOne, responseTwo, responseThree);
        })
      )
      .catch((errors) => {
        console.log(errors);
      });
  };
  // resumePatchData()

  const valueHider = (value, subValue) => {
    if (
      value[subValue] === "Enter Address" ||
      value[subValue] === "Enter Phone" ||
      value[subValue] === "Enter Email" ||
      value[subValue] === "Enter Name" ||
      value[subValue] === "Enter Experience" ||
      value[subValue] === "Enter Languages"
    ) {
      return (value[subValue] = "");
    } else {
      return value[subValue];
    }
  };

  const contactInfoGenerator = (key, value) => {
    if (key === "address") {
      return {
        [key]: value,
        phone: editFields.phone,
        exp: editFields.exp,
        languages: editFields.languages,
      };
    } else if (key === "phone") {
      return {
        address: editFields.address,
        [key]: value,
        exp: editFields.exp,
        languages: editFields.languages,
      };
    } else if (key === "exp") {
      return {
        address: editFields.address,
        phone: editFields.phone,
        [key]: value,
        languages: editFields.languages,
      };
    } else if (key === "languages") {
      return {
        address: editFields.address,
        phone: editFields.phone,
        exp: editFields.exp,
        [key]: value,
      };
    }
  };

  return (
    <>
      <Box>
        {edit ? (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                id="standard-basic"
                variant="standard"
                size="small"
                sx={{
                  flex: "0 0 80%",
                  height: "2rem",
                }}
                value={valueHider(value, subValue)}
                onChange={(e) => {
                  setInputValue({
                    ...value,
                    [subValue]: e.target.value,
                  });
                }}
              />
              <DoneIcon
                sx={{
                  flex: "0 0 20%",
                  fontSize: 20,
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() => {
                  if (value[subValue] !== "") {
                    setEdit(!edit);
                    patchField(subValue, value[subValue]);
                  } else {
                    alert("Please enter a value");
                  }
                }}
              />
            </Box>
          </>
        ) : (
          <>
            <p sx={{ display: "inline !important" }}>
              <li>
                {value[subValue]}
                { candidate ? (
                  <>
                 {!isBtnDisabled &&  isCandidate &&  <EditIcon
                      sx={{
                        fontSize: 15,
                        color: "lightgray",
                        marginLeft: "0.5rem",
                        "&:hover": { color: "gray", cursor: "pointer" },
                        display: "inline",
                      }}
                      onClick={() => {
                        setEdit(!edit);
                        console.log(value[subValue]);
                        console.log(value);
                        // patchField(value[subValue],subValue);
                      }}
                    />}
                  </>
                ) : (
                  <></>
                )}
              </li>
            </p>
          </>
        )}
      </Box>
      <ToastMsg
        isToast={isToast}
        setIsToast={setIsToast}
        data="Profile updated sucessfully"
      />
    </>
  );
};

export default EditContactField;
