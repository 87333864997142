import React from "react";
import { useState } from "react";
import { MdLocationOn } from "react-icons/md";
import { postContactUs } from "components/Fetchers";
import newLogo from "../../images/companyLogo.png";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import NewNavbar from "components/Layout/NewNavbar";
import NewFooter from "components/Layout/NewFooter";
import { CgDanger } from "react-icons/cg";
import {
  Address,
  Banglore,
  ContactHeading,
  ContactPara,
  FeedBackHead,
  FeedBackPara,
  Us,
} from "utils/constants";
import { Helmet } from "react-helmet";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContactUs = () => {
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const onSuccess = () => {
    setSuccessOpen(true);
  };
  const onError = () => {
    setErrorOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessOpen(false);
    setErrorOpen(false);
  };

  const [userInput, setUserInput] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    description: "",
    linkedln: "",
  });
  const [validation, setValidaton] = useState("");
  const handleSubmit = (e) => {
    console.log("submit");
    e.preventDefault();
    const valid = contactValidation(
      userInput.name,
      userInput.email,
      userInput.phone
    );
    setValidaton(valid);
    if (valid) return;
    postContactUs(userInput)
      .then((res) => {
        console.log(res);
        onSuccess();
        setValidaton("");
      })
      .catch((err) => {
        onError();
        console.log(err);
      });
  };

  const contactValidation = (name, email, phone) => {
    const isEmail = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(
      email
    );
    const isPhone =
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
        phone
      );
    if (!name) return "Please Enter Your Name!";
    if (!isEmail) return "Email is not valid!";
    if (!isPhone) return "Phone Number is not valid!";
    return null;
  };

  const handleNumber = (value) => {
    return value.replace(/\D/g, "");
  };

  const contactLinks = [
    {
      name: () => {
        return (
          <div className="flex">
            <a href="mailto:hello@workcrew.ai" className="text-blue-900">
              <div className="mb-1">hello@workcrew.ai</div>
            </a>
            <span>&nbsp; | &nbsp;</span>
            <a href="mailto:sales@workcrew.ai" className="text-blue-900">
              <div>sales@workcrew.ai</div>
            </a>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <NewNavbar />
      <Helmet>
        <title>Workcrew.ai value your feedback. Contact us on hello@workcrew.ai
        </title>
        <meta name="description" content="Explore the trendy, featured jobs and opportunities of the tech world." />
      </Helmet>
      <div className="flex flex-col items-center"></div>
      <div className="bg-[#EFF6FF] min-h-screen flex justify-center items-center">
        <div className="w-full max-w-7xl flex flex-col md:flex-row justify-between items-center md:items-center  px-4 py-8">
          <div className="w-full md:w-1/2 flex flex-col items-center md:items-start text-center md:text-left space-y-1 md:mt-10">
            <h2 className=" text-blue-900 text-3xl">{ContactHeading}</h2>
            <p className="paragraph text-blue-900 text-sm md:text-base mb-8">
              {ContactPara}
            </p>
            {contactLinks.map((link, index) => (
              <p
                key={index}
                className=" text-blue-900 text-sm md:text-base mb-8"
              >
                {link.name()}
              </p>
            ))}
            <div className="hidden md:flex justify-between items-start bg-blue-50 rounded-lg space-x-8">
              <div className="max-w-xs">
                <h3 className="font-bold text-lg text-blue-900">
                  {FeedBackHead}
                </h3>
                <p className="text-blue-900">{FeedBackPara}</p>
              </div>

              <div className="max-w-xs">
                <h3 className="font-bold text-lg text-blue-900">
                  {Address} <MdLocationOn />
                </h3>
                <p className="text-blue-900">{Banglore}</p>
                <p className="text-blue-900">{Us}</p>
              </div>
            </div>
            <div className="heading z-10 text-4xl md:text-6xl mx-auto">
              <div className="w-[50%] md:w-[30%] flex justify-center md:justify-center items-center mt-8 md:mt-0 mx-auto">
                <img
                  className="landingVector w-full m-auto md:mt-10"
                  src={newLogo}
                  alt="landing-vector"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center w-full md:w-[50%]">
            <div className="bg-white flex flex-col justify-center items-center mx-auto mb-12 rounded-lg shadow-lg max-w-lg p-8">
              <h1 className="text-2xl md:text-3xl font-bold mb-6 md:mb-2  font-outfit">
                Contact Us
              </h1>

              <form onSubmit={handleSubmit} className="w-full">
                <div className="flex flex-col items-center justify-center space-y-4">
                  <input
                    type="text"
                    placeholder="Name *"
                    value={userInput.name}
                    onChange={(e) =>
                      setUserInput({ ...userInput, name: e.target.value })
                    }
                    className="w-full md:w-96 p-3 rounded-sm focus:ring-2 border border-blue-900 focus:ring-blue-500"
                  />

                  <input
                    type="email"
                    required
                    placeholder="Email *"
                    value={userInput.email}
                    onChange={(e) =>
                      setUserInput({ ...userInput, email: e.target.value })
                    }
                    className="w-full md:w-96 p-3 rounded-sm focus:ring-2 border border-blue-900 focus:ring-blue-500"
                  />

                  <input
                    type="text"
                    required
                    placeholder="Phone Number *"
                    value={userInput.phone}
                    onChange={(e) =>
                      setUserInput({
                        ...userInput,
                        phone: handleNumber(e.target.value),
                      })
                    }
                    className="w-full md:w-96 p-3 rounded-sm focus:ring-2 border border-blue-900 focus:ring-blue-500"
                  />

                  <input
                    type="text"
                    placeholder="Company"
                    value={userInput.company}
                    onChange={(e) =>
                      setUserInput({ ...userInput, company: e.target.value })
                    }
                    className="w-full md:w-96 p-3 rounded-sm focus:ring-2 border border-blue-900 focus:ring-blue-500"
                  />

                  <input
                    type="text"
                    placeholder="Linkedln Profile"
                    value={userInput.linkedln}
                    onChange={(e) =>
                      setUserInput({ ...userInput, linkedln: e.target.value })
                    }
                    className="w-full md:w-96 p-3 rounded-sm focus:ring-2 border border-blue-900 focus:ring-blue-500"
                  />

                  <textarea
                    placeholder="Description"
                    value={userInput.description}
                    rows="6"
                    onChange={(e) =>
                      setUserInput({
                        ...userInput,
                        description: e.target.value,
                      })
                    }
                    className="w-full md:w-96 p-3 rounded-lg focus:ring-2 border border-blue-900 focus:ring-blue-500"
                  />
                  {validation ? (
                    <p className="text-red-500 flex justify-center items-center">
                      <CgDanger className="mr-1" />
                      {validation}
                    </p>
                  ) : (
                    <></>
                  )}

                  <button
                    type="submit"
                    className="w-full md:w-[80%] bg-[#2489FE] border-none text-white p-3 font-semibold hover:bg-blue-900 transition duration-300"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <NewFooter />
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} sx={{ width: "100%" }} severity="error">
          Cant Able To Connect To Backend Server
        </Alert>
      </Snackbar>
      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} sx={{ width: "100%" }} severity="success">
          Enquiry is Submitted
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactUs;
