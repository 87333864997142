import React, { useState } from "react";
import add from "../../images/add.png";
import { useSelector } from "react-redux";

const RotateBtn = ({ head, setAddInput, addInput, length }) => {
  const [isRotated, setIsRotated] = useState(false);
  const isCandidate = useSelector((store)=>store?.candidate?.isCandidate);



  const handleAddBtn = ({}) => {
    setIsRotated(!isRotated);
    setAddInput(!addInput);
  };
  return (
    <div className="flex justify-between items-center">
      <h2 className="text-sm md:text-xl font-bold mb-4">
        {head}
        {length ? `(${length})` : ""}
      </h2>
      {isCandidate && <img
        className={`w-8 h-8 md:w-10 md:h-10 cursor-pointer transtion-transform duration-500 ${
          isRotated ? "rotate-45" : "rotate-0"
        }`}
        src={add}
        onClick={handleAddBtn}
      />}
    </div>
  );
};

export default RotateBtn;
