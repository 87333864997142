import React from "react";
import NewNavbar from "components/Layout/NewNavbar";
import HeroSection from "components/Homepage/HeroSection";
import NewBottomSection from "components/Homepage/NewBottomSection";
import NewFooter from "components/Layout/NewFooter";
import NewFeaturedJob from "components/Homepage/NewFeaturedJob";
import NewEmployersSection from "components/Homepage/NewEmployersSection";
import NewRecruitementCompany from "components/Homepage/NewRecruitementCompany";
import Employers from "components/Homepage/Employers";
import Features from "components/Homepage/Features";
import { Helmet } from "react-helmet";
const NewHomePage = () => {
  const [jobs, setJobs] = React.useState([]);
  return (
    <>
      <div className=" mx-auto font-sans ">
        <Helmet>
          <title>Workcrew.ai: AI-Powered Job Portal for Tech Talent</title>
          <meta name="description" content="Explore the trendy, featured jobs and opportunities of the tech world." ></meta>
          <meta name="Keywords" content="Jobs,Job Search,Find jobs,Jobs in India,Jobs in US, Remote Jobs" />
        </Helmet>
        <NewNavbar />
        <HeroSection jobs={jobs} />
        <Employers jobs={jobs} />
        <NewRecruitementCompany />
        {/* <Features /> */}
        {/* <NewEmployersSection /> */}
        <NewFeaturedJob setJobs={setJobs} />
        <NewBottomSection />
        <NewFooter />
      </div>
    </>
  );
};

export default NewHomePage;
