import { configureStore } from "@reduxjs/toolkit";
import candidateReducer from "./candidateSlice";
import btnsReducer from "./btnsSlice";

const appStore = configureStore({
    reducer: {
        candidate: candidateReducer,
        btns: btnsReducer
    }
})



export default appStore;