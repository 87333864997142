import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Typography,
  Chip,
  Button,
  Snackbar,
  MuiAlert,
  Alert,
} from "components/MUI";
import "../style.css";
import { useAuth } from "utils/auth";
import Navbar from "components/Navbar";
import { useQuery } from "@tanstack/react-query";
import { getUnappliedJobs } from "components/Fetchers";
import NewNavbar from "components/Layout/NewNavbar";

function Job() {
  const navigate = useNavigate();
  const auth = useAuth();
  const token = auth.token;
  const [isCandidate, setIsCandidate] = useState(false);
  const [applied, setApplied] = useState(false);

  useEffect(() => {
    if (auth.userType === "candidate") {
      setIsCandidate(true);
    }
  }, [auth.userType]);

  const { id } = useParams();

  const getOnlyId = (array) => {
    const IdArray = [];
    array.forEach((item) => {
      IdArray.push(item._id);
    });
    return IdArray;
  };
  const isAlreadyApplied = (jobId, array) => {
    if (array.includes(jobId)) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    if (auth.userType === "candidate") {
      getUnappliedJobs().then((res) => {
        setApplied(isAlreadyApplied(id, getOnlyId(res)));
      });
    }
    console.log("applied status");
    console.log(applied);
  }, [auth.userType]);

  const fetchJob = async () => {
    return axios.get(`${process.env.REACT_APP_API__URL}/api/jobpost/${id}`);
  };

  const { isLoading, data, isError, error, status } = useQuery(
    ["job"],
    fetchJob
  );
  const [jobDetail, setJobDetail] = useState([]);
  const [inputValues, setInputValues] = useState({});
  useEffect(() => {
    try {
      setInputValues({ ...data.data.result });
    } catch (err) {
      console.log(err);
    }
  }, [data]);

  const handleApply = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API__URL}/api/candidate/jobapply`, {
        jobId: id,
        status: "Applied",
      })
      .then((res) => {
        // handleApplyAfterEffect(job._id, true)
        console.log("SUCCESS");
        onSuccess();

        console.log(res);
      })
      .catch((err) => {
        onError();
        console.log(err);
        // handleApplyAfterEffect(job._id, false)
      });
    // console.log(job._id)
  };

  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const onSuccess = () => {
    setSuccessOpen(true);
  };
  const onError = () => {
    setErrorOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessOpen(false);
    setErrorOpen(false);
  };

  if (isLoading) {
    return (
      <h2
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: 800,
        }}
      >
        Loading...
      </h2>
    );
  }
  if (isError) {
    return <h2>{error.message}</h2>;
  }

  if (status === "success") {
    console.log(data.data.result);

    return (
      <>
        <div style={{ backgroundColor: "#F9F9F9" }}>
          {/* Navbar */}
          <NewNavbar />

          <Box
            sx={{
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              // padding: "0rem 4rem",
              alignItems: "center",
              flexDirection: "column",
              borderRadius: 5,
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
              maxWidth: "lg",
              marginBottom: "4rem",
            }}
          >
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "90%",
                  marginTop: "3rem",
                  // backgroundColor: "gray",
                }}
              >
                <Box
                  sx={{
                    marginTop: "3rem",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={{
                      fontWeight: "bold",
                    }}
                    className="title"
                  >
                    Job Description
                  </Typography>
                  <Box>
                    {isCandidate ? (
                      <>
                        {applied ? (
                          <>
                            <Typography
                              variant="h5"
                              component="h2"
                              sx={{
                                fontWeight: "bold",
                              }}
                            >
                              Already Applied
                            </Typography>
                          </>
                        ) : (
                          <>
                            <button
                              className="featured__button"
                              onClick={handleApply}
                            >
                              Apply Now
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h5"
                          component="h2"
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          <Link to="/login" style={{ color: "black" }}>
                            Please Login to Apply
                          </Link>
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "flex-start",
                    marginTop: "3rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h5" component="p" className="title">
                      Job Title
                    </Typography>

                    <Typography variant="h6" component="p">
                      {inputValues.title}
                    </Typography>

                    <Typography variant="h5" component="p" className="title">
                      Job Type
                    </Typography>

                    <Typography variant="h6" component="p">
                      {inputValues.type}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h5" component="p" className="title">
                      Job Location
                    </Typography>

                    <Typography variant="h6" component="p">
                      {inputValues.location}
                    </Typography>

                    <Typography variant="h5" component="p" className="title">
                      Job Category
                    </Typography>

                    <Typography variant="h6" component="p">
                      {inputValues.category}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h5" component="p" className="title">
                      Company Photo
                    </Typography>

                    <Box>
                      <img
                        src={inputValues?.company?.photo}
                        alt=""
                        style={{
                          width: "200px",
                          height: "200px",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "1rem",
                    marginTop: "3rem",
                  }}
                >
                  <Typography variant="h5" component="p" className="title">
                    Company Email
                  </Typography>

                  <Typography variant="h6" component="p">
                    {inputValues?.jobPostEmail || inputValues?.company?.email}
                  </Typography>

                  <Typography variant="h5" component="p" className="title">
                    Required Experience
                  </Typography>
                  {console.log(inputValues)}
                  <Typography variant="h6" component="p">
                    {inputValues?.requiredExperience}{" "}
                    {inputValues?.requiredExperience > 1 ? "years" : "year"}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "1rem",
                      marginTop: "3rem",
                    }}
                  >
                    <Typography variant="h5" component="p" className="title">
                      Job Description
                    </Typography>

                    <Typography
                      variant="h6"
                      component="p"
                      sx={{ whiteSpace: "break-spaces" }}
                    >
                      {inputValues?.description}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "2rem",
                      flexDirection: "column",
                      marginTop: "3rem",
                    }}
                  >
                    <div className="flex__item">
                      <Typography
                        variant="h5"
                        component="p"
                        sx={{
                          fontWeight: "bold",
                        }}
                        className="title"
                      >
                        Skills
                      </Typography>
                    </div>
                    <Typography
                      variant="h5"
                      component="p"
                      sx={{
                        fontWeight: "bold",
                      }}
                      className="title"
                    >
                      Must Have Skills
                    </Typography>
                    <div>
                      {inputValues?.mustHaveSkills?.map((skill) => (
                        <Chip
                          sx={{
                            margin: "0.2rem",
                            color: "brown",
                            backgroundColor: "#FBE4E4",
                            padding: "0.2rem",
                          }}
                          label={skill}
                        />
                      ))}
                    </div>

                    <Typography
                      variant="h5"
                      component="p"
                      sx={{
                        fontWeight: "bold",
                      }}
                      className="title"
                    >
                      Good to Have Skills
                    </Typography>
                    <div>
                      {inputValues?.goodToHaveSkills?.map((skill) => (
                        <Chip
                          sx={{
                            margin: "0.2rem",
                            color: "brown",
                            backgroundColor: "#FBE4E4",
                            padding: "0.2rem",
                          }}
                          label={skill}
                        />
                      ))}
                    </div>
                  </Box>

                  <div className="flex__item" style={{ marginTop: "3rem" }}>
                    <Typography
                      variant="h5"
                      component="p"
                      sx={{
                        fontWeight: "bold",
                        marginBottom: "2rem",
                      }}
                      className="title"
                    >
                      Salary Compensation
                    </Typography>
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "80%",
                    }}
                  >
                    <Box
                      className="left"
                      sx={{
                        display: "flex",
                        gap: "4rem",
                        flexDirection: "column",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h5"
                          component="p"
                          sx={{ marginBottom: "1rem" }}
                          className="title"
                        >
                          Min Salary
                        </Typography>

                        {inputValues.minSalary ? (
                          <Typography variant="h6" component="p">
                            {inputValues.minSalary}
                          </Typography>
                        ) : (
                          <Typography variant="h6" component="p">
                            Not Specified
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <Typography
                          variant="h5"
                          component="p"
                          sx={{ marginBottom: "1rem" }}
                          className="title"
                        >
                          Min rate/h
                        </Typography>

                        {inputValues.minRate ? (
                          <Typography variant="h6" component="p">
                            {inputValues.minRate}
                          </Typography>
                        ) : (
                          <Typography variant="h6" component="p">
                            Not Specified
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <Typography
                          variant="h5"
                          component="p"
                          sx={{ marginBottom: "1rem" }}
                          className="title"
                        >
                          Hours Per Week
                        </Typography>

                        {inputValues.hoursPerWeek ? (
                          <Typography variant="h6" component="p">
                            {inputValues.hoursPerWeek}
                          </Typography>
                        ) : (
                          <Typography variant="h6" component="p">
                            Not Specified
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box
                      className="Right"
                      sx={{
                        display: "flex",
                        gap: "4rem",
                        flexDirection: "column",
                        marginBottom: "4rem",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h5"
                          component="p"
                          sx={{ marginBottom: "1rem" }}
                          className="title"
                        >
                          Max Salary
                        </Typography>

                        {inputValues.maxSalary ? (
                          <Typography variant="h6" component="p">
                            {inputValues.maxSalary}
                          </Typography>
                        ) : (
                          <Typography variant="h6" component="p">
                            Not Specified
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <Typography
                          variant="h5"
                          component="p"
                          sx={{ marginBottom: "1rem" }}
                          className="title"
                        >
                          Max rate/h
                        </Typography>

                        {inputValues.maxRate ? (
                          <Typography variant="h6" component="p">
                            {inputValues.maxRate}
                          </Typography>
                        ) : (
                          <Typography variant="h6" component="p">
                            Not Specified
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <Typography
                          variant="h5"
                          component="p"
                          sx={{ marginBottom: "1rem" }}
                          className="title"
                        >
                          External Apply for Job Link
                        </Typography>

                        {inputValues.externalLink ? (
                          <Typography variant="h6" component="p">
                            {inputValues.externalLink}
                          </Typography>
                        ) : (
                          <Typography variant="h6" component="p">
                            Not Specified
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
            <Snackbar
              open={errorOpen}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={handleClose}
                sx={{ width: "100%" }}
                severity="error"
              >
                Cant Apply to the job, Please Try Again
              </Alert>
            </Snackbar>
            <Snackbar
              open={successOpen}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={handleClose}
                sx={{ width: "100%" }}
                severity="success"
              >
                Job Applied!
              </Alert>
            </Snackbar>
          </Box>
        </div>
      </>
    );
  }
}

export default Job;
