import Projects from "components/CandidateProfile/Projects";
import { BTN_SEE_ALL, PROJECT_HEAD } from "utils/constants";
import AddProjects from "./AddProjects";
import { useState } from "react";
import RotateBtn from "components/Button/RotateBtn";
import { Alert, Snackbar } from "@mui/material";
import { deletePersonalProjectItem } from "components/Fetchers";
import ToastMsg from "./ToastMsg";
import { useSelector } from "react-redux";

const ProjectDetails = ({ candidateData, activeButton }) => {
  const { personalProject, skills } = candidateData;
  const [addInput, setAddInput] = useState(false);
  const [projectList, setProjectList] = useState(personalProject || []);
  const [editProject, setEditProject] = useState(null);
  const [isToast, setIsToast] = useState(false);
  const isBtnDisabled = useSelector((store)=>store?.btns?.isBtnDisabled);

  const handleAddProject = (newProject) => {
    if (editProject) {
      setProjectList((prevList) =>
        prevList.map((project) =>
          project._id === editProject._id ? newProject : project
        )
      );
    } else {
      setProjectList((prevList) => [...prevList, newProject]);
    }
    setEditProject(null);
    setIsToast(true);
  };

  const renderProjects = (projects) =>
    projects.map((project) => (
      <Projects
        key={project._id}
        project={project}
        skills={skills}
        handleEdit={handleEdit}
      />
    ));

  const handleEdit = (id) => {
    const selectedProject = projectList.find((item) => item._id === id);
    setEditProject(selectedProject);
    setAddInput(true);
  };

  const handleDelete = async (id) => {
    debugger;
    const newArray = projectList.filter((project, i) => {
      return project._id !== id;
    });
    await deletePersonalProjectItem(candidateData._id, id);
    setProjectList(newArray);
    setAddInput(false);
  };

  const hasProjects = projectList.length > 0;

  return (
    <div className="bg-white p-6 rounded-md">
    {!isBtnDisabled &&   <RotateBtn
        head={PROJECT_HEAD}
        setAddInput={setAddInput}
        addInput={addInput}
        length={projectList.length}
      />}
      {addInput && (
        <AddProjects
          candidateData={candidateData}
          handleAddProject={handleAddProject}
          handleEdit={handleEdit}
          editProject={editProject}
          handleDelete={handleDelete}
        />
      )}
      {!hasProjects ? (
        <p className="text-gray-500">No project works available.</p>
      ) : (
        renderProjects(activeButton ? projectList : projectList.slice(0, 1))
      )}
      {/* {hasProjects && (
        <button className="ml-8 w-28 border-none bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors duration-300 mt-5">
          {BTN_SEE_ALL}
        </button>
      )} */}
      <ToastMsg
        isToast={isToast}
        setIsToast={setIsToast}
        data="Project added successfully!"
        severity="success"
      />
    </div>
  );
};

export default ProjectDetails;
